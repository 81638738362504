<template>
  <el-dialog
      :visible.sync="dialogVisible"
      width="429px">
    <img class="icon" src="@/assets/images/icon_bd@2x.png" alt="">
    <h1>温馨提示</h1>
    <p>尊敬的客户您好，对于上月账单数据是否确认无误，请确认！</p>
    <div class="btn-group">
      <div class="btn" @click="addReport('a')">有疑问</div>
      <div class="btn" @click="addReport('b')">确认无误</div>
    </div>

  </el-dialog>
</template>

<script>

export default {
  name: "ReminderDialog",
  data() {
    return {
      dialogVisible: false
    };
  },
  components: {},
  props: {},
  methods: {
    open() {
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    //添加查看对账结果
    async addReport(status) {
      let res = await this.$api.addReport({
        status: status
      });
      if (res.code == 1) {
        this.dialogVisible = false;
        if (status == "a") {
          await this.$router.push("/complaint")
        } else if (status == "b") {
          console.log("确认无误")
        }
      } else {
        this.$message.error(res.msg);
      }

    },
  }
}
</script>

<style lang="scss" scoped>
.el-dialog__body {
  .icon {
    width: 64px;
    height: 64px;
    display: block;
    margin: 0 auto;
  }

  h1 {
    margin-top: 10px;

    font-size: 18px;
    font-weight: 500;
    color: #333333;
    text-align: center;
  }

  p {
    width: 314px;
    margin: 20px auto 0;
    font-size: 14px;
    color: #333333;
    line-height: 30px;
  }

  .btn-group {
    margin: 30px auto;
    width: 208px;
    overflow: hidden;

    .btn {
      cursor: pointer;
      width: 88px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      font-size: 16px;

      &:first-child {
        float: left;
        background-color: #DADADA;
        color: black;
      }

      &:nth-child(2) {
        float: right;
        background-color: $color-primary;
        color: white;
      }
    }
  }
}
</style>