<template>
  <div class="Home-container">
    <transition name="el-fade-in-linear">
      <router-view></router-view>
    </transition>
    <!--温馨提示弹窗1-->
    <ReminderDialog2 ref="reminderDialog2"></ReminderDialog2>
  </div>
</template>

<script>
import ReminderDialog2 from "@/components/dialogs/ReminderDialog2";

export default {
  name: "Home",
  data() {
    return {};
  },
  components: {ReminderDialog2},
  props: {},
  mounted() {
    this.report();
  },
  methods: {
    //判断是否需要查看对账
    async report() {
      let res = await this.$api.report();
      if (res.code == 1) {
        if (res.data == 0) {
          console.log(res.msg)
        } else if (res.data == 1) {
          this.$refs.reminderDialog2.open();
        } else {
          this.$message.error(res.msg);
        }
      } else {
        this.$message.error(res.msg);
        if (res.code == 401) {
          await this.$router.push("/login");
          this.$store.commit("LOGOUT");
        }
      }

    },
  },
}
</script>

<style lang="scss" scoped>
.Home-container {

}

</style>